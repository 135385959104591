import { Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import {
    ZenegyEmployeeTypeEnum,
    ZenegySalaryModeEnum,
    ZenegySalaryTypeEnum
} from '../../../models/payment';
import { useErrorHandling } from '../../../utils/errorHandling';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../components/shared/SearchInput';
import { Dropdown, DropdownType } from '../../../components/shared/Dropdown';
import { SMBold } from '../../../typography/Typography';
import { SearchGrid } from '../../../components/SearchGrid';
import { ActiveFilter } from '../UserActiveFilter';
import {
    getZenegyUsersFilters,
    saveZenegyUserFilters
} from '../../../lib/localStorage';
import { useAppDispatch, usePayments } from '../../../lib/hooks';
import {
    getCurrentPaymentCompany,
    getZenegyDepartments,
    InstaPaidSalaryTypes
} from '../../../lib/slices/paymentsSlice';
import { FETCH_STATE } from '../../../lib/slices/types';
import { SecondaryButtonRegular } from '../../../components/shared/Button';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import {
    useEmployeeDepartments,
    useEmployeeTypes
} from '../../../services/paymentsQuery';
import { setLoader } from '../../../lib/slices/globalLoaderSlice';

export type ZenegyUsersFiltersProps = {
    selectedDepartments: string[];
    onDepartmentChange: (value: string[]) => void;
    selectedSalaryModes: ZenegySalaryModeEnum[];
    onSalaryModeChange: (value: string | string[]) => void;
    selectedSalaryTypes: ZenegySalaryTypeEnum[];
    onSalaryTypeChange: (value: string | string[]) => void;
    selectedEmployeeTypes: ZenegyEmployeeTypeEnum[];
    onEmployeeTypeChange: (value: string | string[]) => void;
    selectedPlanDayEmployeeTypes: string[];
    onPlanDayEmployeeTypeChange: (value: string | string[]) => void;
    username: string;
    onUsernameChange: (name: string) => void;
    selectedActiveType: string;
    onActiveTypeChange: (value: string | string[]) => void;
    selectedInstaPaidSalaryTypes: string[];
    onInstaPaidSalaryTypeChange: (value: string | string[]) => void;
};

export const UsersFilters = (props: ZenegyUsersFiltersProps) => {
    const getEmployeeDepartments = useEmployeeDepartments();
    const getEmployeeTypes = useEmployeeTypes();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setLoader(
                getEmployeeTypes.isPending || getEmployeeDepartments.isPending
            )
        );
    }, [getEmployeeTypes.isPending, getEmployeeDepartments.isPending]);

    useEffect(() => {
        if (getEmployeeTypes.isError) {
            backendErrorHandler('Error fetching employee types ');
        }
    }, [getEmployeeTypes.isError]);

    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const handleEmployeeTypeChange = (value: string[]) => {
        props.onEmployeeTypeChange(value);
        saveZenegyUserFilters({
            selectedEmployeeTypes: value as ZenegyEmployeeTypeEnum[]
        });
    };

    const handleDepartmentChange = (value: string[]) => {
        props.onDepartmentChange(value);
        saveZenegyUserFilters({ selectedDepartments: value });
    };

    const handleSalaryModesChange = (value: string | string[]) => {
        props.onSalaryModeChange(value);
        saveZenegyUserFilters({
            selectedSalaryModes: value as ZenegySalaryModeEnum[]
        });
    };

    const handlePlandayEmployeeTypesChange = (value: string | string[]) => {
        props.onPlanDayEmployeeTypeChange(value);
        saveZenegyUserFilters({ selectedPandayEmployeeIds: value });
    };

    const handleSalaryTypesChange = (value: string | string[]) => {
        props.onSalaryTypeChange(value);
        saveZenegyUserFilters({
            selectedSalaryTypes: value as ZenegySalaryTypeEnum[]
        });
    };

    const handleActiveTypeChange = (value: string | string[]) => {
        props.onActiveTypeChange(value);
        saveZenegyUserFilters({ selectedActive: value as string });
    };

    const handleInstaPaidSalaryTypeChange = (value: string) => {
        props.onInstaPaidSalaryTypeChange(value);
        saveZenegyUserFilters({ selectedInstaPaidSalaryTypes: value });
    };

    const { zenegyDepartments, planDayEmployeeType, paymentCompany } =
        usePayments();

    useEffect(() => {
        setLoading(zenegyDepartments.status == FETCH_STATE.LOADING);
        if (zenegyDepartments.status == FETCH_STATE.SUCCEEDED) {
            let filters = getZenegyUsersFilters();
            handleDepartmentChange(filters?.selectedDepartments ?? []);
            handleEmployeeTypeChange(filters?.selectedEmployeeTypes ?? []);
            handleSalaryTypesChange(filters?.selectedSalaryTypes ?? []);
            handleActiveTypeChange(filters?.selectedActive ?? []);
            handleInstaPaidSalaryTypeChange(
                filters?.selectedInstaPaidSalaryTypes ?? ''
            );
        }
        if (zenegyDepartments.error) {
            backendErrorHandler('Error fetching departments: ');
        }
    }, [zenegyDepartments]);

    useEffect(() => {
        setLoading(planDayEmployeeType.status == FETCH_STATE.LOADING);
        if (planDayEmployeeType.status == FETCH_STATE.SUCCEEDED) {
            let filters = getZenegyUsersFilters();
            handlePlandayEmployeeTypesChange(
                filters?.selectedPandayEmployeeIds ?? []
            );
        }
        if (planDayEmployeeType.error) {
            backendErrorHandler('Error fetching Planday employee types: ');
        }
    }, [planDayEmployeeType]);

    useEffect(() => {
        setLoading(paymentCompany.status == FETCH_STATE.LOADING);
        if (paymentCompany.status == FETCH_STATE.SUCCEEDED) {
            let filters = getZenegyUsersFilters();
            handleSalaryModesChange(filters?.selectedSalaryModes ?? []);
        }
        if (paymentCompany.error) {
            backendErrorHandler('Error fetching company details: ');
        }
    }, [paymentCompany]);

    const { setAlert } = useNotificationsSnackbar();

    useEffect(() => {
        dispatch(getZenegyDepartments());
        dispatch(getCurrentPaymentCompany());

        let filters = getZenegyUsersFilters();

        if (!filters) {
            return;
        }

        let saved = false;
        Object.keys(filters).forEach((x) => {
            if (filters[x] != '' && filters[x]?.length > 0) {
                saved = true;
            }
        });

        if (saved) {
            setAlert('Previous filters restored', 'success');
        }
    }, []);

    const resetFilters = () => {
        handleDepartmentChange([]);
        handleSalaryModesChange([]);
        handlePlandayEmployeeTypesChange([]);
        handleEmployeeTypeChange([]);
        handleSalaryTypesChange([]);
        handleActiveTypeChange('');
        handleInstaPaidSalaryTypeChange('');
    };

    const { t } = useTranslation();

    return (
        <SearchGrid container columnSpacing={1}>
            <Grid item xs={2}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    justifyContent={'space-between'}
                >
                    <div>
                        <SMBold noWrap={true}>{t('Filter by: ')}</SMBold>
                    </div>

                    <SearchInput
                        onDebouncedTextChange={props.onUsernameChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
                <Dropdown
                    label="Department"
                    choices={getEmployeeDepartments?.data?.data?.departments
                        .map((department) => ({
                            label: department.departmentName,
                            value: department.departmentId
                        }))
                        .sort((a, b) => a.label?.localeCompare(b.label))}
                    value={props.selectedDepartments}
                    type={DropdownType.Multi}
                    onChange={handleDepartmentChange}
                />
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <Dropdown
                    label="Employee type"
                    choices={getEmployeeTypes?.data?.data?.employeeTypes
                        ?.map((type) => ({
                            label: type.employeeTypeName,
                            value: type.employeeTypeId
                        }))
                        .sort((a, b) => a.label?.localeCompare(b.label))}
                    type={DropdownType.Multi}
                    value={props.selectedEmployeeTypes}
                    onChange={handleEmployeeTypeChange}
                />
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <Dropdown
                    label="Salary type"
                    type={DropdownType.Single}
                    choices={InstaPaidSalaryTypes}
                    value={props.selectedInstaPaidSalaryTypes}
                    onChange={handleInstaPaidSalaryTypeChange}
                />
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <ActiveFilter
                    selectedActiveType={props.selectedActiveType}
                    onActiveTypeChange={handleActiveTypeChange}
                />
            </Grid>
            <Grid item>
                <SecondaryButtonRegular onClick={resetFilters}>
                    <Typography
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={600}
                        noWrap
                    >
                        Reset
                    </Typography>
                </SecondaryButtonRegular>
            </Grid>
        </SearchGrid>
    );
};
