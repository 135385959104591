import { Backdrop, CircularProgress, debounce } from '@mui/material';
import { useLoader } from './LoaderContext';
import { useLoader as useGlobalLoader } from '../../lib/hooks';
import { useEffect, useState } from 'react';

export const Loader = () => {
    const { loading } = useLoader();

    const { isLoading } = useGlobalLoader();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
            open={loading || isLoading}
        >
            <CircularProgress sx={{ color: 'inherit' }} />
        </Backdrop>
    );
};
