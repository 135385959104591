import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PaymentsAPIServiceV2 from './PaymentsServiceV2';
import ReportsInternalService, {
    EmployeeDetailRequest
} from './ReportsInternalService';

const reportsService = ReportsInternalService.getInstance();
const paymentsService = PaymentsAPIServiceV2.getInstance();

export const REPORTS_QUERY_KEYS = {
    GET_EMPLOYEE_DETAILS: 'GET_EMPLOYEE_DETAILS',
    GET_EMPLOYEE_SICKNESS: 'GET_EMPLOYEE_SICKNESS',
    GET_DEPARTMENTS: 'GET_DEPARTMENTS'
};

export const useReportsQuery = ({
    enableGetEmployeeDetailsQuery = false,
    enableGetEmployeeSicknessQuery = false,
    enableGetDepartmentsQuery = false,
    employeeDetailParams = undefined
}: {
    enableGetEmployeeDetailsQuery?: boolean;
    enableGetEmployeeSicknessQuery?: boolean;
    enableGetDepartmentsQuery?: boolean;
    employeeDetailParams?: EmployeeDetailRequest;
}) => {
    const getEmployeeDetailsQuery = useQuery({
        queryKey: [
            REPORTS_QUERY_KEYS.GET_EMPLOYEE_DETAILS,
            employeeDetailParams
        ],
        queryFn: () => reportsService.getEmployeeDetails(employeeDetailParams!),
        enabled: enableGetEmployeeDetailsQuery
    });

    const getEmployeeSicknessQuery = useQuery({
        queryKey: [
            REPORTS_QUERY_KEYS.GET_EMPLOYEE_SICKNESS,
            employeeDetailParams
        ],
        queryFn: () =>
            reportsService.getEmployeeSickness(
                employeeDetailParams!.departments
            ),
        enabled: enableGetEmployeeSicknessQuery
    });

    const getDepartmentsQuery = useQuery({
        queryKey: [REPORTS_QUERY_KEYS.GET_DEPARTMENTS],
        queryFn: () => reportsService.getDepartments(),
        enabled: enableGetDepartmentsQuery
    });

    return {
        getEmployeeDetailsQuery,
        getEmployeeSicknessQuery,
        getDepartmentsQuery
    };
};
