import styled from '@emotion/styled';
import { FormControl, Typography } from '@mui/material';
import { CalenderIcon } from '../../icons/CalenderIcon';
import { TEXT_SIZE } from '../../typography/Typography';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import { SecondaryButtonRegular, PrimaryButton } from './Button';

const DropDown = styled('div')`
    margin: 0 auto;
    z-index: 10000;
    min-width: auto;
    position: relative;
`;

const DropDownBtn = styled('div')`
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #777;
    font-weight: 500;
    padding: 8px 14px;
`;

const DropDownItem = styled('div')`
    display: block;
    padding: 8px;
    margin: 10px;
    line-height: 16px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;

    &:hover {
        background: #eff8ff;
    }
`;

const DropDownContent = styled('div')`
    position: absolute;
    left: 0;
    min-width: -webkit-fill-available;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
    background: white;
    border-radius: 10px;
`;

const DatePickerFooter = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-left: 1px solid #f0f0f0;
`;

const DateInput = styled('input')`
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    width: 90px;
    height: 30px;
    text-align: center;
    margin: 5px;
`;

const DatePickerButtons = styled('div')`
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: space-evenly;
`;

interface MonthRangeDropDownProps {
    startDate?: Date | null;
    endDate?: Date | null;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
}

export const MonthRangeDropDown = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate
}: MonthRangeDropDownProps) => {
    const [isActive, setIsActive] = useState(false);

    const [showDatePicker, setShowDatePicker] = useState(false);

    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const [isApplied, setIsApplied] = useState(false);

    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
        null
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        console.log('dates', dates);
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    useEffect(() => {
        if (isApplied) {
            setStartDate(selectedStartDate!);
            setEndDate(selectedEndDate!);
            setIsApplied(false);
            setIsActive(false);
        }
    }, [isApplied, selectedStartDate, selectedEndDate]);

    const handleApply = () => {
        setIsApplied(true);
        setIsActive(false);
    };

    return (
        <FormControl>
            <DropDown style={{ zIndex: 1 }}>
                <DropDownBtn onClick={(e) => setIsActive(!isActive)}>
                    <CalenderIcon />
                    <Typography
                        marginLeft={'10px'}
                        color={'#344054'}
                        fontWeight={600}
                        fontSize={TEXT_SIZE.MEDIUM_SMALL}
                        lineHeight={'20px'}
                    >
                        {startDate ? dayjs(startDate).format('MMMM, YYYY') : ''}{' '}
                        - {endDate ? dayjs(endDate).format('MMMM, YYYY') : ''}
                    </Typography>
                </DropDownBtn>
                <DropDownContent
                    style={{
                        display: isActive ? 'flex' : 'none'
                    }}
                >
                    <div
                        style={{
                            display: 'block',
                            textWrap: 'nowrap'
                        }}
                    ></div>
                    <div style={{ display: 'block' }}>
                        <DatePicker
                            calendarStartDay={1}
                            selected={startDate}
                            onChange={onChange}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            monthsShown={2}
                            // locale={'en-GB'}
                            selectsRange
                            inline
                            shouldCloseOnSelect={false}
                            showMonthYearPicker
                        />

                        <DatePickerFooter>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <DateInput
                                    value={
                                        selectedStartDate
                                            ? dayjs(selectedStartDate).format(
                                                  'MMMM, YYYY'
                                              )
                                            : ''
                                    }
                                    readOnly
                                />
                                <p> - </p>
                                <DateInput
                                    value={
                                        selectedEndDate
                                            ? dayjs(selectedEndDate).format(
                                                  'MMMM, YYYY'
                                              )
                                            : ''
                                    }
                                    readOnly
                                />
                            </div>
                            <DatePickerButtons>
                                {/* <SecondaryButtonRegular onClick={() => {}}>
                            {t('close')}
                        </SecondaryButtonRegular> */}
                                <PrimaryButton onClick={handleApply}>
                                    {t('apply')}
                                </PrimaryButton>
                            </DatePickerButtons>
                        </DatePickerFooter>
                    </div>
                </DropDownContent>
            </DropDown>
        </FormControl>
    );
};
