import { Grid, Alert, Box } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useBalance } from './useBalance';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    FONT_WEIGHT,
    Heading,
    LINE_HEIGHT,
    TEXT_SIZE
} from '../../typography/Typography';
import Chip from '../../components/shared/Chip';
import { AvailableBalance } from './balance/AvailableBalance';
import { MileageOverview } from './balance/MileageOverview';
import { BalanceSummary } from './balance/BalanceSummary';
import { ViewSwitch } from '../../components/shared/ViewSwitch';
import { ShiftListType, ShiftsTable } from './shifts/ShiftsTable';
import { useShifts } from './useShifts';

import {
    useAppDispatch,
    useCompany,
    usePayments,
    useUsers
} from '../../lib/hooks';
import {
    getAvailableShifts,
    getCurrentPaymentCompany,
    getUserTransactionsHistory,
    getUpcomingShifts,
    setAiiaRetryErrorHandled,
    getCurrentCompany
} from '../../lib/slices/paymentsSlice';
import { BalanceSummaryDetailsDialog } from './balance/BalanceSummaryDetailsDialog';
import {
    PrimaryButton,
    PrimaryInvertedButton
} from '../../components/shared/Button';
import { useTranslation } from 'react-i18next';
import { UpdateUserForm } from './update/UpdateUserForm';
import { UpdateBankAccountForm } from './bankAccountDetails/UpdateBankAccountForm';
import {
    getUserCurrentCompany,
    getUserData,
    sendActivationEmail
} from '../../lib/slices/usersSlice';
import { FETCH_STATE } from '../../lib/slices/types';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useFormatting } from '../../utils/formatting';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { UserTransactions } from './UserTransactions';
import { UserContext, useUser } from '../../contexts/user/UserContext';
import { UserRoles } from '../../components/sidebar/MenuTree';
import { useBalanceV2, useWithdrawalValidation } from '@instapaid/shared-logic';
import { AvailableShiftsTable } from './shifts/AvailableShiftsTable';
import { DATE_FORMAT } from '../../lib/constants';
import { useGetUserQuery } from '../../services/paymentsQuery';

export const UserDetailsPage = () => {
    let { userId } = useParams();

    const { balance, isLoading: isBalanceLoading } = useBalance(userId!);

    const { t } = useTranslation();

    const { user } = useUser();

    const {
        shifts: doneShifts,
        shiftsError,
        isLoading: isShiftLoading
    } = useShifts(userId!, balance?.details?.employmentType);

    const { userData, currentUserCompany } = useUsers();
    const dispatch = useAppDispatch();

    const payrollPeriodStr = !!balance?.details?.payrollPeriodStart
        ? dayjs(balance?.details?.payrollPeriodStart).format(
              DATE_FORMAT.DATE_MONTH_YEAR
          ) +
          ' - ' +
          dayjs(balance?.details?.payrollPeriodEnd).format(
              DATE_FORMAT.DATE_MONTH_YEAR
          )
        : 'Not set';

    const { formatDate, getBalanceLabel, toDecimal } = useFormatting();

    const {
        upcomingShifts,
        availableShifts,
        userTransactionHistory,
        paymentCompany,
        aiiaRefreshPayment,
        aiiaRetryAuthorize
    } = usePayments();

    useEffect(() => {
        if (aiiaRefreshPayment.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('status_refreshed'), 'success');
        } else if (aiiaRefreshPayment.status == FETCH_STATE.FAILED) {
            setAlert(t('status_refresh_failed'), 'error');
        }
    }, [aiiaRefreshPayment.status]);

    useEffect(() => {
        if (aiiaRetryAuthorize.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('success'), 'success');
        } else if (aiiaRetryAuthorize.status == FETCH_STATE.FAILED) {
            setAlert(t('transaction_retry_failed'), 'error');
            dispatch(setAiiaRetryErrorHandled());
        }
    }, [aiiaRetryAuthorize.status]);

    const currentPeriodShifts = upcomingShifts.currentPeriodShifts;

    useEffect(() => {
        dispatch(
            setLoader(
                userData.status == FETCH_STATE.LOADING ||
                    currentUserCompany?.status == FETCH_STATE.LOADING ||
                    aiiaRefreshPayment.status == FETCH_STATE.LOADING ||
                    aiiaRetryAuthorize.status == FETCH_STATE.LOADING ||
                    upcomingShifts.isLoading ||
                    availableShifts.isLoading ||
                    paymentCompany.status == FETCH_STATE.LOADING ||
                    isShiftLoading ||
                    isBalanceLoading
            )
        );
    }, [
        userData.status,
        currentUserCompany?.status,
        aiiaRefreshPayment.status,
        aiiaRetryAuthorize.status,
        upcomingShifts.isLoading,
        availableShifts.isLoading,
        paymentCompany.status,
        isShiftLoading,
        isBalanceLoading
    ]);

    const { setAlert } = useNotificationsSnackbar();

    useEffect(() => {
        if (shiftsError) {
            setAlert('Could not fetch shifts for user', 'error');
        }
    }, [shiftsError]);

    const [tabIndex, setTabIndex] = useState<number | null>(null);

    const availableShiftsArr = availableShifts?.availableShifts?.shifts;

    const { awaitingAndAvailableHours } = useBalanceV2(balance?.details!);

    useEffect(() => {
        dispatch(getUserData(userId!));
        dispatch(getCurrentPaymentCompany());
        dispatch(getUserCurrentCompany());
        dispatch(
            getUserTransactionsHistory({ userId: userId!, limit: 7, offset: 0 })
        );
    }, [userId]);

    useEffect(() => {
        if (balance?.details?.employmentType === 'Hourly') {
            dispatch(getUpcomingShifts(userId!));
            dispatch(getAvailableShifts(userId!));
        }
    }, [balance, userId]);

    useEffect(() => {
        if (shiftsError) {
            setAlert('Could not fetch shifts for user', 'error');
        }
    }, [shiftsError]);

    const payrollStartStr = formatDate(
        new Date(balance?.details?.payrollPeriodStart ?? '')
    );

    const payrollEndStr = formatDate(
        new Date(balance?.details?.payrollPeriodEnd ?? '')
    );

    const nextPayrollStartStr = formatDate(dayjs().toDate());

    const nextPayrollEndStr = formatDate(dayjs().add(30, 'days').toDate());

    const navigate = useNavigate();

    const viewSwitchItems = useMemo(() => {
        const hourlyEmployeeItems = () => {
            if (
                !balance ||
                !doneShifts ||
                !doneShifts ||
                !currentPeriodShifts ||
                !availableShiftsArr
            ) {
                return [];
            }

            return balance?.details?.employmentType === 'Hourly'
                ? [
                      {
                          primaryText: 'Worked shifts',
                          badgeText: doneShifts?.length.toString(),
                          route: 'balance',
                          routeComponent: (
                              <ShiftsTable
                                  heading={t('payroll_period_text', {
                                      startDate: payrollStartStr,
                                      endDate: payrollEndStr
                                  })}
                                  shiftListType={ShiftListType.WORKED}
                                  shifts={doneShifts}
                                  userId={userId!}
                                  payrollPeriod={payrollPeriodStr}
                                  shiftStatusWithdrawAllowed={
                                      balance?.details
                                          ?.shiftStatusWithdrawAllowed
                                  }
                                  payoutType={
                                      paymentCompany?.company
                                          ?.shiftStatusWithdrawAllowed ?? ''
                                  }
                                  companyName={
                                      currentUserCompany?.company?.name ?? ''
                                  }
                              />
                          )
                      },
                      {
                          primaryText: 'Upcoming shifts',
                          badgeText:
                              currentPeriodShifts?.shifts.length.toString() ??
                              '0',
                          route: 'upcoming-shifts',
                          routeComponent: (
                              <ShiftsTable
                                  heading={t('payroll_period_text', {
                                      startDate: payrollStartStr,
                                      endDate: payrollEndStr
                                  })}
                                  shifts={currentPeriodShifts?.shifts}
                                  userId={userId!}
                                  payrollPeriod={payrollPeriodStr}
                                  shiftListType={ShiftListType.UPCOMING}
                                  shiftStatusWithdrawAllowed={
                                      balance?.details
                                          ?.shiftStatusWithdrawAllowed
                                  }
                                  payoutType={
                                      paymentCompany?.company
                                          ?.shiftStatusWithdrawAllowed ?? ''
                                  }
                                  companyName={
                                      currentUserCompany?.company?.name ?? ''
                                  }
                              />
                          )
                      },
                      {
                          primaryText: 'Available shifts',
                          badgeText:
                              availableShiftsArr?.length?.toString() ?? '0',
                          route: 'available-shifts',
                          routeComponent: (
                              <AvailableShiftsTable
                                  heading={t('next_30_days', {
                                      startDate: nextPayrollStartStr,
                                      endDate: nextPayrollEndStr
                                  })}
                                  shifts={availableShiftsArr}
                                  userId={userId!}
                                  payrollPeriod={payrollPeriodStr}
                                  shiftStatusWithdrawAllowed={
                                      balance?.details
                                          ?.shiftStatusWithdrawAllowed
                                  }
                                  payoutType={
                                      paymentCompany?.company
                                          ?.shiftStatusWithdrawAllowed ?? ''
                                  }
                                  companyName={
                                      currentUserCompany?.company?.name ?? ''
                                  }
                              />
                          )
                      }
                  ]
                : [];
        };

        const allEmployeeItems = () => {
            const employeeItems = [];

            if (userTransactionHistory) {
                employeeItems.push({
                    primaryText: 'Transaction history',
                    badgeText:
                        userTransactionHistory.transactions?.total?.toString() ??
                        '0',
                    route: `user-transactions/page/0`,
                    path: 'user-transactions/page/:pageNumber',
                    overrideTestPath: 'user-transactions/page/',
                    routeComponent: <UserTransactions userId={userId!} />
                });
            }

            return employeeItems;
        };

        return [...hourlyEmployeeItems(), ...allEmployeeItems()];
    }, [
        userId,
        balance?.details?.shiftStatusWithdrawAllowed,
        doneShifts,
        doneShifts,
        currentPeriodShifts,
        availableShiftsArr,
        userTransactionHistory,
        payrollPeriodStr
    ]);

    const [showBalanceDetails, setShowBalanceDetails] =
        useState<boolean>(false);
    const [updateUserDialogOpen, setUpdateUserDialogOpen] =
        useState<boolean>(false);
    const [
        updateBankAccountUserDialogOpen,
        setUpdateBankAccountUserDialogOpen
    ] = useState<boolean>(false);

    const handleShowDetailsClick = () => {
        setShowBalanceDetails(true);
    };

    const handleShowDetailsCloseClick = () => {
        setShowBalanceDetails(false);
    };

    const openUpdateUserDialog = () => {
        setUpdateUserDialogOpen(true);
    };
    const openUpdateBankAccountUserDialog = () => {
        setUpdateBankAccountUserDialogOpen(true);
    };

    const handleSendActivationEmail = () => {
        dispatch(sendActivationEmail(userId!));
    };

    const { sendActivationMail } = useUsers();

    useEffect(() => {
        dispatch(setLoader(sendActivationMail.status === FETCH_STATE.LOADING));
        if (sendActivationMail.error) {
            setAlert('failed', 'error');
        }
        if (sendActivationMail.status === FETCH_STATE.SUCCEEDED) {
            setAlert('Activation email sent', 'success');
        }
    }, [sendActivationMail.status, sendActivationMail.error]);

    useEffect(() => {
        if (balance?.details?.employmentType == 'Fixed') {
            navigate('./user-transactions/page/0', { replace: true });
        }
    }, [balance?.details?.employmentType]);

    const { company: companyData } = useCompany();

    const { company } = useContext(UserContext);

    const { data } = useGetUserQuery({
        userId: userId,
        enabled: true
    });

    const {
        bankDetailsEmpty,
        alreadyProcessingTransactions,
        currentMonthPayrollExists,
        isNemkonto
    } = useWithdrawalValidation(
        companyData,
        balance?.details,
        {
            bankCode: data?.data?.bankCode,
            accountNumber: data?.data?.bankAccountNumber,
            cprNumber: data?.data?.cprNumber,
            isNemkonto: data?.data?.isNemkonto
        },
        undefined
    );

    return (
        <>
            {userData.status == FETCH_STATE.SUCCEEDED && (
                <>
                    <Grid container md={12}>
                        <Grid
                            md={12}
                            container
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={3}
                            ml={1}
                        >
                            <Grid
                                container
                                md={6}
                                direction={'row'}
                                gap={1}
                                alignItems={'center'}
                            >
                                <Box mt={1} ml={1}>
                                    <Link to={-1 as any}>
                                        <ArrowBackIosIcon />
                                    </Link>
                                </Box>

                                <Heading
                                    fontSize={TEXT_SIZE.XLARGE}
                                    fontWeight={FONT_WEIGHT.MEDIUM}
                                    lineHeight={LINE_HEIGHT.XLARGE}
                                >
                                    {userData.data?.firstName}{' '}
                                    {userData.data?.lastName}
                                </Heading>
                                <Chip label={payrollPeriodStr} />
                            </Grid>

                            <Grid
                                container
                                gap={1}
                                item
                                md={5}
                                alignItems={'end'}
                                justifyContent={'flex-end'}
                                mr={1.5}
                            >
                                <PrimaryInvertedButton
                                    onClick={handleSendActivationEmail}
                                >
                                    {t('send_activation_code')}
                                </PrimaryInvertedButton>
                                <PrimaryButton onClick={openUpdateUserDialog}>
                                    {t('edit_personal_info')}
                                </PrimaryButton>
                                <PrimaryButton
                                    onClick={openUpdateBankAccountUserDialog}
                                >
                                    {t('edit_bank')}
                                </PrimaryButton>
                            </Grid>

                            {/* <Link to={-1 as any} style={{ marginRight: '20px' }}>
                    <CloseIcon />
                </Link> */}
                        </Grid>
                        {balance && balance.error && (
                            <Alert
                                sx={{ marginTop: '1rem', width: '100%' }}
                                severity="error"
                            >
                                Could not calculate balance for this employee.
                                Received error "{balance.error}"
                            </Alert>
                        )}

                        {balance && balance.error === null && (
                            <Grid container md={12}>
                                <Grid md={12} container>
                                    <Grid md={4}>
                                        <AvailableBalance
                                            balance={balance?.balance}
                                            earnings={balance?.details?.earned}
                                            allowancePercentage={
                                                balance?.details
                                                    ?.allowancePercentage
                                            }
                                        />
                                    </Grid>
                                    <Grid md={4}>
                                        <MileageOverview
                                            mileageTotal={
                                                balance?.details?.mileageTotal
                                            }
                                            mileageKm={
                                                balance?.details?.mileageKm
                                            }
                                        />
                                    </Grid>

                                    <Grid md={4}>
                                        <BalanceSummary
                                            onShowAllDetails={
                                                handleShowDetailsClick
                                            }
                                            taxRate={
                                                balance?.details
                                                    .calculationsDetails
                                                    ?.taxRate
                                            }
                                            taxDeduction={
                                                balance?.details
                                                    .calculationsDetails
                                                    ?.taxDeduction
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    {user.role == UserRoles.SuperAdmin && (
                        <>
                            {' '}
                            <Box
                                pt={2}
                                pl={1}
                                flexDirection={'row'}
                                gap={2}
                                display={'flex'}
                            >
                                <Chip
                                    label={
                                        'Available Hours: ' +
                                        toDecimal(
                                            awaitingAndAvailableHours?.availableHours,
                                            2
                                        )
                                    }
                                    variant="outlined"
                                />
                                <Chip
                                    label={
                                        'Awaiting Hours: ' +
                                        toDecimal(
                                            awaitingAndAvailableHours?.awaitingHours,
                                            2
                                        )
                                    }
                                    variant="outlined"
                                />
                                <Chip
                                    label={
                                        'Upcoming Shifts Total: ' +
                                        balance?.details
                                            ?.upcomingShiftsTotalHours +
                                        ' hours upto ' +
                                        getBalanceLabel(
                                            balance?.details
                                                ?.upcomingShiftsTotalAmount,
                                            company?.currency
                                        )
                                    }
                                    variant="outlined"
                                />

                                <Chip
                                    label={
                                        'Available Shifts Total Count: ' +
                                        balance?.details
                                            ?.availableShiftsTotalCount +
                                        ' shifts upto ' +
                                        getBalanceLabel(
                                            balance?.details
                                                ?.availableShiftsTotalAmount,
                                            company?.currency
                                        )
                                    }
                                    variant="outlined"
                                />

                                {isNemkonto && (
                                    <Chip
                                        label={'Nemkonto'}
                                        variant="outlined"
                                    />
                                )}
                            </Box>
                            {alreadyProcessingTransactions && (
                                <Alert
                                    sx={{
                                        marginTop: '1rem',
                                        width: '100%'
                                    }}
                                    severity="warning"
                                >
                                    {t('pending_approval_cannot_withdraw')}
                                </Alert>
                            )}
                            {currentMonthPayrollExists && (
                                <Alert
                                    sx={{
                                        marginTop: '1rem',
                                        width: '100%'
                                    }}
                                    severity="warning"
                                >
                                    {t('cannot_withdraw_payslip_exists')}
                                </Alert>
                            )}
                            {bankDetailsEmpty && (
                                <Alert
                                    sx={{
                                        marginTop: '1rem',
                                        width: '100%'
                                    }}
                                    severity="warning"
                                >
                                    {t(
                                        'cannot_withdraw_because_bank_details_not_available'
                                    )}
                                </Alert>
                            )}
                        </>
                    )}

                    <>
                        <Box mt={4} />
                        <ViewSwitch
                            itemList={viewSwitchItems}
                            setSelectedIndex={setTabIndex}
                            selectedIndex={tabIndex}
                        />
                    </>

                    <BalanceSummaryDetailsDialog
                        payrollDateString={payrollPeriodStr}
                        balance={balance}
                        onClose={handleShowDetailsCloseClick}
                        open={showBalanceDetails}
                    />

                    <UpdateUserForm
                        onUserListChange={() => {}}
                        userId={userId}
                        open={updateUserDialogOpen}
                        setOpen={(open: boolean) =>
                            setUpdateUserDialogOpen(open)
                        }
                    />
                    <UpdateBankAccountForm
                        onUserListChange={() => {}}
                        userId={userId}
                        open={updateBankAccountUserDialogOpen}
                        setOpen={(open: boolean) =>
                            setUpdateBankAccountUserDialogOpen(open)
                        }
                    />
                </>
            )}
        </>
    );
};
