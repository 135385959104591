import { Box, Typography } from '@mui/material';
import { ContentWrapper } from '../../components/ContentWrapper';
import {
    FONT_WEIGHT,
    Heading,
    LINE_HEIGHT,
    PageSubHeading,
    TEXT_SIZE
} from '../../typography/Typography';
import { SicknessReport } from '../../components/reports/SicknessReport';
import { SicknessDashboard } from '../../components/reports/SicknessDashboard';
import { ViewSwitch } from '../../components/shared/ViewSwitch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ReportsDashboard = () => {
    const { t } = useTranslation();

    const viewSwitchItems = [
        {
            primaryText: t('dashboard'),
            route: 'dashboard',
            badgeText: '',
            routeComponent: <SicknessDashboard />
        },
        {
            primaryText: t('sickness_report'),
            route: 'sickness_report',
            badgeText: '',
            routeComponent: <SicknessReport />
        }
    ];
    const [tabIndex, setTabIndex] = useState<number | null>(null);

    return (
        <ContentWrapper>
            <>
                <Box m={1} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h4" marginBottom="5px">
                        <Heading
                            fontSize={TEXT_SIZE.MEDIUM_LARGE}
                            fontWeight={FONT_WEIGHT.MEDIUM}
                            lineHeight={LINE_HEIGHT.XLARGE}
                        >
                            Reports
                        </Heading>
                        <PageSubHeading>
                            Access your reports here.
                        </PageSubHeading>
                    </Typography>
                </Box>
                {/* <ChartPOC /> */}

                {/* <ChartPOC /> */}

                <ViewSwitch
                    itemList={viewSwitchItems}
                    setSelectedIndex={setTabIndex}
                    selectedIndex={tabIndex}
                />
            </>
        </ContentWrapper>
    );
};
