import GradingIcon from '@mui/icons-material/Grading';
import { UserRole } from '../../models/company';
import { DashboardIcon } from '../../icons/DashboardIcon';
import { PendingApprovalsIcon } from '../../icons/PendingApprovalsIcon';
import { EmployeesIcon } from '../../icons/EmployeesIcon';
import { TransactionsIcon } from '../../icons/TransactionsIcon';
import { ReconciliationIcon } from '../../icons/ReconciliationIcon';
import { CompanyIcon } from '../../icons/CompanyIcon';
import { CompanyAccountsIcon } from '../../icons/CompanyAccountsIcon';
import { EmailsIcon } from '../../icons/EmailsIcon';
import { BankIcon } from '../../icons/BankIcon';
import { PayrollIcon } from '../../icons/PayrollIcon';
import { TimeRegistrationIcon } from '../../icons/TimeRegistrationIcon';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { SubscriptionIcon } from '../../icons/SubscriptionIcon';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { useEffect, useMemo, useState } from 'react';
import { useAdminCompanies, useAppDispatch, useLoader } from '../../lib/hooks';
import { getAdminCompanies } from '../../lib/slices/paymentsSlice';
import { FETCH_STATE } from '../../lib/slices/types';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useNavigate } from 'react-router-dom';
import {
    getCompanyIdFromLocalStorage,
    setCompanyIdInLocalStorage
} from '../../services/companyService';
import { useUser } from '../../contexts/user/UserContext';
//import DashboardIcon

export type MenuLink = {
    label: string;
    icon: JSX.Element;
    url: string;
    requiredRole?: UserRole;
};

export enum UserRoles {
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin'
}

type MenuTree = Array<{
    label?: string;
    role: UserRoles;
    items: MenuLink[];
}>;

const sideBarIconFontSize = { fontSize: '18px' };

export const useMenuTree = () => {
    const dispatch = useAppDispatch();

    const { user, company } = useUser();

    const { status, companies } = useAdminCompanies();

    useEffect(() => {
        dispatch(getAdminCompanies());
    }, []);

    useEffect(() => {
        dispatch(setLoader(status == FETCH_STATE.LOADING));
    }, [status]);

    const navigate = useNavigate();

    const [selectedCompanyName, setSelectedCompanyName] = useState<
        string | null
    >(null);

    useEffect(() => {
        const selectedCompanyId = getCompanyIdFromLocalStorage();

        if (companies?.length == 1 && !selectedCompanyId) {
            navigate(`/?changeCompanyId=${companies[0].companyId}`);
            return;
        }

        const selectedCompany = companies?.find(
            (c) => c.companyId == selectedCompanyId
        );

        if (selectedCompany) {
            setSelectedCompanyName(selectedCompany.name);
        }
    }, [companies]);

    const getAdminLinks = () => {
        if (user.role == UserRoles.SuperAdmin) {
            return {
                label: 'Insta Admin',
                role: UserRoles.SuperAdmin,
                items: [
                    {
                        url: '/companies/v2',
                        label: 'Companies',
                        icon: <CompanyIcon style={sideBarIconFontSize} />
                    },
                    {
                        url: '/companyAccounts',
                        label: 'Company accounts',
                        icon: (
                            <CompanyAccountsIcon style={sideBarIconFontSize} />
                        )
                    },
                    {
                        url: '/all-employees/page/0',
                        label: 'All employees',
                        icon: <EmployeesIcon style={sideBarIconFontSize} />
                    },
                    {
                        url: '/emails',
                        label: 'Emails',
                        icon: <EmailsIcon style={sideBarIconFontSize} />
                    }
                ]
            };
        } else {
            return {
                label: 'Group',
                role: UserRoles.Admin,
                items: [
                    {
                        url: '/companies/v2',
                        label: 'Companies',
                        icon: <DashboardIcon style={sideBarIconFontSize} />
                    }
                ]
            };
        }
    };

    let commonLinks = [
        {
            label: selectedCompanyName ?? 'General',
            role: UserRoles.Admin,
            items: [
                {
                    url: '/',
                    label: 'Dashboard',
                    icon: <DashboardIcon style={sideBarIconFontSize} />
                },
                {
                    url: '/payments',
                    label: 'Pending transactions',
                    icon: <PendingApprovalsIcon style={sideBarIconFontSize} />
                },
                {
                    url: '/history/page/0',
                    label: 'Transactions',
                    icon: <TransactionsIcon style={sideBarIconFontSize} />
                },
                {
                    url: '/users-list/page/0',
                    label: 'Employees',
                    icon: <EmployeesIcon style={sideBarIconFontSize} />
                },
                {
                    url: '/reconciliation',
                    label: 'Reconciliation',
                    icon: <ReconciliationIcon style={sideBarIconFontSize} />
                }
            ]
        },
        {
            label: 'Account',
            role: UserRoles.Admin,
            items: [
                {
                    url: '/settings',
                    label: 'Settings',
                    icon: <SettingsIcon style={sideBarIconFontSize} />
                },
                {
                    url: '/subscription',
                    label: 'Subscription',
                    icon: <SubscriptionIcon style={sideBarIconFontSize} />
                }
            ]
        }
    ];

    const logoutOptions = [
        {
            role: UserRoles.Admin,
            items: [{ icon: <LogoutIcon />, url: 'logout', label: 'Logout' }]
        }
    ];

    const reportOption = {
        url: '/reports',
        label: 'Reports',
        icon: <DashboardIcon style={sideBarIconFontSize} />
    };

    const menuTree: MenuTree = useMemo(() => {
        if (!getCompanyIdFromLocalStorage()) {
            return [getAdminLinks(), ...logoutOptions];
        }

        if (company.showReports) {
            commonLinks[0].items.push(reportOption);
        }

        if (companies.length == 1) {
            return [...commonLinks, ...logoutOptions];
        }

        return [getAdminLinks(), ...commonLinks, ...logoutOptions];
    }, [companies, user, selectedCompanyName, company]);

    return { menuTree } as const;
};
