import {
    Box,
    Grid,
    Stack,
    Table,
    TableBody,
    TableContainer
} from '@mui/material';

import {
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from '../../components/shared/styles/Table';
import { Heading, SMBold } from '../../typography/Typography';
import { Dropdown } from '../shared/Dropdown';
import { useEffect, useState } from 'react';
import ReportsInternalService from '../../services/ReportsInternalService';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useNotificationsSnackbar } from '../snackbar/NotificationsSnackbarContext';
import { useFormatting } from '../../utils/formatting';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SearchGrid } from '../SearchGrid';
import Chip from '../shared/Chip';
import { useReportsQuery } from '../../services/reportsQuery';

export const SicknessReport = () => {
    const dispatch = useAppDispatch();

    const [departments, setDepartments] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string>();

    const [employeesList, setEmployeesList] = useState<any[]>([]);
    const { setAlert } = useNotificationsSnackbar();

    const { toDecimal } = useFormatting();

    const { t } = useTranslation();

    const { getEmployeeSicknessQuery, getDepartmentsQuery } = useReportsQuery({
        enableGetEmployeeDetailsQuery: false,
        enableGetDepartmentsQuery: true,
        enableGetEmployeeSicknessQuery: departments?.length > 0,
        employeeDetailParams: {
            departments:
                selectedDepartment == 'All' ? undefined : selectedDepartment
        }
    });

    useEffect(() => {
        if (getEmployeeSicknessQuery.data) {
            setEmployeesList(getEmployeeSicknessQuery.data.data.employees);
        }
    }, [getEmployeeSicknessQuery.data]);

    useEffect(() => {
        if (getDepartmentsQuery.data) {
            setDepartments([
                'All',
                ...getDepartmentsQuery.data.data.plandayDepartments.map(
                    (d: any) => d.name
                )
            ]);
            setSelectedDepartment('All');
        }
    }, [getDepartmentsQuery.data]);

    useEffect(() => {
        dispatch(setLoader(getEmployeeSicknessQuery.isPending));
    }, [getEmployeeSicknessQuery.isPending]);

    useEffect(() => {
        if (getEmployeeSicknessQuery.isError) {
            setAlert('Failed to fetch sickness details', 'error');
        }
    }, [getEmployeeSicknessQuery.isError]);

    return (
        <Box>
            <SearchGrid container justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <div>
                        <SMBold noWrap={true}>{t('filter_by')}:</SMBold>
                    </div>
                    <div style={{ width: '220px' }}>
                        <Dropdown
                            label={''}
                            choices={departments.map((d, index) => {
                                return { label: d, value: d };
                            })}
                            value={selectedDepartment}
                            onChange={function (value: any | any[]): void {
                                setSelectedDepartment(value);
                            }}
                        ></Dropdown>
                    </div>
                </Stack>
            </SearchGrid>
            <Box mt={5}>
                <Grid pt={2} pl={2} pr={2} container alignItems={'center'}>
                    <Grid
                        item
                        md={10}
                        flexDirection={'row'}
                        display={'flex'}
                        gap={1}
                        alignItems={'center'}
                    >
                        <Heading style={{ display: 'inline' }}>
                            Sickness Report
                        </Heading>
                        <Chip label={selectedDepartment} />
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <TableContainer
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 40vh)'
                        }}
                    >
                        <Table>
                            <TableHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}
                            >
                                <TableHeaderCell>
                                    {t('employee_name')}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {t('department')}
                                </TableHeaderCell>

                                {employeesList?.[0]?.sickDays?.map(
                                    (sickDay: any) => {
                                        return (
                                            <TableHeaderCell
                                                align="right"
                                                key={sickDay?.monthName}
                                                style={{ minWidth: '75px' }}
                                            >
                                                {sickDay?.monthName}{' '}
                                                {dayjs(
                                                    sickDay?.year?.toString()
                                                ).format('YY')}
                                            </TableHeaderCell>
                                        );
                                    }
                                )}

                                <TableHeaderCell
                                    align="right"
                                    style={{
                                        minWidth: '70px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {t('sum')}
                                </TableHeaderCell>
                            </TableHeader>
                            <TableBody>
                                {employeesList.map((employee) => {
                                    return (
                                        <TableRow key={employee.id}>
                                            <TableCell
                                            // style={{ textWrap: 'nowrap' }}
                                            >
                                                {employee?.employeeName}
                                            </TableCell>

                                            <TableCell>
                                                {employee?.departmentName}
                                            </TableCell>

                                            {employee?.sickDays?.map(
                                                (
                                                    sickDay: any,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <TableCell
                                                            align="right"
                                                            key={index}
                                                        >
                                                            {sickDay?.hours
                                                                ? toDecimal(
                                                                      parseFloat(
                                                                          sickDay?.hours
                                                                      ),
                                                                      2
                                                                  )
                                                                : '-'}
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {toDecimal(
                                                    parseFloat(employee?.sum),
                                                    2
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
};
