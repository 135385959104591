import { useCallback, useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { ZenegyEmployeesOverview } from './list/EmployeesOverview';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { Alert, Stack } from '@mui/material';
import {
    PageHeading,
    PageSubHeading,
    SMBold
} from '../../typography/Typography';
import { usePayments } from '../../lib/hooks';
import Chip, { chipColors } from '../../components/shared/Chip';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { UserType } from '../../models/company';

export const UsersPage = () => {
    const { zenergyUsersDetailed } = usePayments();

    const renderPageHeading = useCallback((total?: number) => {
        return (
            <Stack direction={'row'} alignItems={'center'}>
                <PageHeading mr={1}>Employees</PageHeading>{' '}
                <Chip
                    color={'success'}
                    label={
                        <SMBold color={chipColors.success.text}>{total}</SMBold>
                    }
                />
            </Stack>
        );
    }, []);

    return (
        <ContentWrapper>
            <>
                {renderPageHeading(zenergyUsersDetailed.usersResponse?.total)}
                <PageSubHeading>
                    Get an overview of the employees within your organization
                </PageSubHeading>
                <ZenegyEmployeesOverview userType={UserType.Normal} />
            </>
        </ContentWrapper>
    );
};
