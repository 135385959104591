export const paymentSources = {
    instaPaidAccount: 'InstaPaidAccount',
    companyAccount: 'CompanyAccount'
};

export const paymentProviders = {
    aiia: 'Aiia',
    danskeBank: 'DanskeBank'
};

export const approvalTypes = {
    manual: 'Manual',
    automatic: 'Automatic'
};

export const AUTO_APPROVAL_TIME_PROVIDERS = [
    'Planday',
    'Staff',
    'Tamigo',
    'SameSystem'
];
export const ALLOW_CONNECTION_TIME_REGISTRATION_EDIT = [
    'Planday',
    'Zenegy',
    'Tamigo',
    'SameSystem'
];

export const CREDENTIAL_BASED_AUTH_PROVIDERS = [
    'Planday',
    'Tamigo',
    'SameSystem'
];

export const OAUTH_BASED_AUTH_PROVIDERS = ['Zenegy'];
