import { MSALHolder } from '../lib/msal';
import BaseAPI from './baseServiceV2';
import { AxiosInstance } from 'axios';

export interface EmployeeDetailResponse {
    totalEmployees: number;
    totalNewHires: number;
    totalChurned: number;
    employmentDates: EmploymentDate[];
    employeeGender: EmployeeGender;
    employeeGenderByAge: EmployeeGenderByAge[];
    sickDays: SickDays;
    sickDaysByAge: SickDaysByAge[];
}

export interface EmploymentDate {
    date: string;
    churnedCount: number;
    hiredCount: number;
}

export interface EmployeeGender {
    maleCount: number;
    malePercentage: number;
    femaleCount: number;
    femalePercentage: number;
}

export interface EmployeeGenderByAge {
    description: string;
    totalEmployees: number;
    maleCount: number;
    femaleCount: number;
}

export interface SickDays {
    totalWorkedDays: number;
    totalSickDays: number;
    sickDaysPercentage: number;
}

export interface SickDaysByAge {
    description: string;
    totalEmployees: number;
    totalWorkedDays: number;
    totalSickDays: number;
}

export interface EmployeeDetailRequest {
    departments?: string;
    startDate?: string;
    endDate?: string;
}

class ReportsInternalService extends BaseAPI {
    client!: AxiosInstance;

    static instance: ReportsInternalService;

    private constructor() {
        super();
        (async () => {
            const REPORTS_INTERNAL_API_BASE_URL = (
                await MSALHolder.getInstance()
            ).getSpaConfiguration()?.backendReportsInternalServiceURL;
            this.client = this.getClient(REPORTS_INTERNAL_API_BASE_URL!);
        })();

        ReportsInternalService.instance = this;
    }

    getDepartments = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`api/departments`, { headers });
    };

    getEmployeeSickness = async (
        departments?: string,
        startDate?: string,
        endDate?: string
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`api/employeeSickness`, {
            headers,
            params: { departments: departments, startDate, endDate }
        });
    };

    getEmployeeDetails = async ({
        departments,
        startDate,
        endDate
    }: EmployeeDetailRequest): Promise<{
        data: EmployeeDetailResponse;
    }> => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`api/employeeDetails`, {
            headers,
            params: { departments, startDate, endDate }
        });
    };

    static getInstance = () => {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new ReportsInternalService();
            return this.instance;
        }
    };
}

export default ReportsInternalService;
