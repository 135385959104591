import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import { Typography } from '@mui/material';

interface ChipColorVariants {
    primary: ChipColor;
    info: ChipColor;
    error: ChipColor;
    default: ChipColor;
    icon?: React.ReactNode;
}

export type ChipProps = MuiChipProps & {
    icon?: React.ReactNode;
};

interface ChipColor {
    background: string;
    text: string;
}

export const chipColors: ChipColorVariants | any = {
    success: {
        background: '#ECFDF3',
        dot: '#10B981',
        text: '#047857'
    },
    info: {
        background: '#EFF8FF',
        dot: '#175CD3',
        text: '#175CD3'
    },
    muted: {
        background: '#F2F4F7',
        dot: '#667085',
        text: '#344054'
    },
    error: {
        background: '#FEE4E2',
        dot: '#F04438',
        text: '#D92D20'
    },
    warning: {
        background: '#FFFBEB',
        dot: '#F59E0B',
        text: '#B45309'
    },
    default: {
        background: '#ECFDF3',
        dot: '#10B981',
        text: '#047857'
    }
};

export default function Chip({ color, icon, label, ...props }: ChipProps) {
    const colorObject = (color && chipColors[color]) || chipColors.default;

    return (
        <MuiChip
            variant="filled"
            style={{
                backgroundColor: colorObject.background,
                color: colorObject.text,
                padding: '0px 0px',
                height: '22px',
                display: 'flex',
                width: 'fit-content'
            }}
            label={label}
            {...props}
            icon={
                icon ?? (
                    <CircleIcon
                        style={{ color: colorObject.dot }}
                        fontSize={'2px' as any}
                    />
                )
            }
        >
            {props.children}
        </MuiChip>
    );
}
