import {
    Box,
    Grid,
    Stack,
    Table,
    TableBody,
    TableContainer
} from '@mui/material';

import {
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from '../../components/shared/styles/Table';
import { Heading, SMBold } from '../../typography/Typography';
import { Dropdown } from '../shared/Dropdown';
import { useEffect, useState } from 'react';
import ReportsInternalService from '../../services/ReportsInternalService';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useNotificationsSnackbar } from '../snackbar/NotificationsSnackbarContext';
import { useFormatting } from '../../utils/formatting';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SearchGrid } from '../SearchGrid';
import Chip from '../shared/Chip';
import { useReportsQuery } from '../../services/reportsQuery';
import { SicknessDashboardCharts } from './SicknessDashboardCharts';
import { MonthRangeDropDown } from '../shared/MonthRangeDropDown';

export const SicknessDashboard = () => {
    const dispatch = useAppDispatch();

    const [departments, setDepartments] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string>();

    const [employeesList, setEmployeesList] = useState<any[]>([]);
    const { setAlert } = useNotificationsSnackbar();

    const { toDecimal } = useFormatting();

    const { t } = useTranslation();

    const [startDate, setStartDate] = useState<Date>(
        dayjs().startOf('month').toDate()
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs().endOf('month').toDate()
    );

    const { getEmployeeDetailsQuery, getDepartmentsQuery } = useReportsQuery({
        enableGetEmployeeDetailsQuery: departments?.length > 0,
        enableGetDepartmentsQuery: true,
        employeeDetailParams: {
            departments:
                selectedDepartment == 'All' ? undefined : selectedDepartment,
            startDate: dayjs(startDate)?.format('YYYY-MM-DD'),
            endDate: dayjs(endDate).endOf('month').format('YYYY-MM-DD')
        }
    });

    useEffect(() => {
        if (getDepartmentsQuery.data) {
            setDepartments([
                'All',
                ...getDepartmentsQuery.data.data.plandayDepartments.map(
                    (d: any) => d.name
                )
            ]);
            setSelectedDepartment('All');
        }
    }, [getDepartmentsQuery.data]);

    useEffect(() => {
        if (selectedDepartment && startDate && endDate) {
            getEmployeeDetailsQuery.refetch();
        }
    }, [selectedDepartment, startDate, endDate]);

    useEffect(() => {
        dispatch(setLoader(getEmployeeDetailsQuery.isPending));
    }, [getEmployeeDetailsQuery.isPending]);

    useEffect(() => {
        if (getEmployeeDetailsQuery.isError) {
            setAlert('Failed to fetch employee details', 'error');
        }
    }, [getEmployeeDetailsQuery.isError]);

    return (
        <Box>
            <SearchGrid container justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <div>
                        <SMBold noWrap={true}>{t('filter_by')}:</SMBold>
                    </div>
                    <div>
                        <MonthRangeDropDown
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                    <div style={{ width: '220px' }}>
                        <Dropdown
                            label={''}
                            choices={departments.map((d, index) => {
                                return { label: d, value: d };
                            })}
                            value={selectedDepartment}
                            onChange={function (value: any | any[]): void {
                                setSelectedDepartment(value);
                            }}
                        ></Dropdown>
                    </div>
                </Stack>
            </SearchGrid>
            <SicknessDashboardCharts
                getEmployeeDetailsQuery={getEmployeeDetailsQuery}
            />
        </Box>
    );
};
