import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { IntectEmploymentTemplatesResponse } from '../../models/payment';
import { SearchGrid } from '../../components/SearchGrid';
import { SMBold } from '../../typography/Typography';
import { SearchInput } from '../../components/shared/SearchInput';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../components/shared/Button';
import { saveIntectUserFilters } from '../../lib/localStorage';
import { ActiveFilter } from '../users/UserActiveFilter';

export type EmployeesFiltersProps = {
    onUsernameChange: (name: string) => void;
    selectedActiveType: string;
    onActiveTypeChange: (value: string | string[]) => void;
    onResetFilters: () => void;
};

export const EmployeesFilters = (props: EmployeesFiltersProps) => {
    useState<IntectEmploymentTemplatesResponse>();

    const { t } = useTranslation();

    const resetFilters = () => {
        handleActiveTypeChange([]);
    };

    const handleActiveTypeChange = (value: string | string[]) => {
        props.onActiveTypeChange(value);
        saveIntectUserFilters({ selectedActive: value as string });
    };

    return (
        <SearchGrid container columnSpacing={1}>
            <Grid item xs={4}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    justifyContent={'space-between'}
                >
                    <div>
                        <SMBold noWrap={true}>{t('Filter by: ')}</SMBold>
                    </div>

                    <SearchInput
                        onDebouncedTextChange={props.onUsernameChange}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <ActiveFilter
                    selectedActiveType={props.selectedActiveType}
                    onActiveTypeChange={handleActiveTypeChange}
                    type="AllEmployees"
                />
            </Grid>

            <Grid item>
                <PrimaryButton onClick={resetFilters}>
                    <Typography
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={600}
                        noWrap
                    >
                        {t('reset_filter')}
                    </Typography>
                </PrimaryButton>
            </Grid>
        </SearchGrid>
    );
};
