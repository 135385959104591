export enum ShiftStatusWithdrawAllowed {
  PunchclockFinished = "PunchclockFinished",
  Registered = "Registered",
  Approved = "Approved",
}

export interface BalanceDetails {
  earned: number;
  withholdings: number;
  earnedAfterWithholdings: number;
  additionalAdjustments: number;
  withdrawed: number;
  allowancePercentage: number;
  allowancePercentageDeduction: number;
  start: string;
  end: string;
  payrollPeriodStart: string;
  payrollPeriodEnd: string;
  workdays: number;
  totalBusinessDaysInMonth: number;
  workedHoursPercent: number;
  mileageKm: number;
  mileageTotal: number;
  hasMileageRecordsTripDetails: boolean;
  pendingWithdrawals: number;
  cannotWithdrawAfterPayRollProcessed: boolean;
  unapprovedHoursWithdrawAllowed: boolean;
  registeredHours: number;
  approvedHours: number;
  availableShiftsTotalCount: number;
  upcomingShiftsTotalHours: number;
  employmentType?: "Hourly" | "Fixed";
  shiftStatusWithdrawAllowed: ShiftStatusWithdrawAllowed;
  unavailableShiftsTotalAmount: number;
  upcomingShiftsTotalAmount: number;
  availableShiftsTotalAmount: number;
  hoursSummary: {
    registered: number;
    punchclockStarted: number;
    punchclockFinished: number;
    approved: number;
  };
  calculationsDetails: {
    earningsBeforeTax: number;
    amContributionAfterATP: number;
    earningsAfterTax: number;
    monthlySalary: number | null;
    monthlyNumberOfHours: number | null;
    taxDeduction: number;
    atpContribution: number;
    appliedTaxDeduction: number;
    amContribution: number;
    aIncome: number;
    taxableIncome: number;
    taxRate: number;
    incomeTax: number;
    atpContributionBracket: string;
  };
  balanceAdjustments: {
    totalAdjustmentsBeforeTax: number;
    totalAdjustmentsAfterAM: number;
    totalAdjustmentsAfterTax: number;
  };
}

export interface Theme {
  id: string;
  name: string;
  logoName: string;
  colors: any;
}

export interface CompanyResponse {
  allowancePercentage: number;
  latestWithdrawalDay: any;
  withdrawalsLocked: boolean;
  shiftStatusWithdrawAllowed?: ShiftStatusWithdrawAllowed;
  companyId: string;
  createdAt: Date;
  name: string;
  currency: string;
  greetingText: string;
  theme: Theme;
  loginType: LoginType;
  endOfTrial: Date | null;
  timeRegistrationProvider: string;
  requiredConsentsAccepted: boolean;
  showReports: boolean;
}

export enum LoginType {
  AzureB2C = "AzureB2C",
  AzureAD = "AzureAD",
}

export interface BankDetailsResponse {
  bankCode?: string | null;
  accountNumber?: string | null;
  cprNumber?: string;
  isNemkonto?: boolean;
}

export interface PendingTransactionsResponse {
  pendingTransactionsCount: number;
}
