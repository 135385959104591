/* istanbul ignore file */
import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import { MSALHolder } from '../lib/msal';

import { PopupRequest } from '@azure/msal-browser';
import { getImpersonatedUserId } from './impersonationService';
import { getCompanyIdFromLocalStorage } from './companyService';
import { logout } from '../utils/logout';

export const getPersistedLoginType = () => {
    return window.localStorage.getItem('loginType') as string;
};

const isoDateFormat = () =>
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: any): boolean => {
    return value && typeof value === 'string' && isoDateFormat().test(value);
};

const handleDates = (body: any) => {
    if (body === null || body === undefined || typeof body !== 'object')
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value);
        else if (typeof value === 'object') handleDates(value);
    }
};

class BaseAPIServiceV2 {
    getClient = (baseUrl: string): AxiosInstance => {
        const axiosConfig = {
            baseURL: baseUrl
        };
        const client = axios.create(axiosConfig);

        client.interceptors.response.use((originalResponse) => {
            handleDates(originalResponse.data);
            return originalResponse;
        });

        return client;
    };

    //private token?: string = undefined;

    buildGetQuery = (getParams: object) => {
        var str = [];
        for (var getParam in getParams)
            if (
                getParams.hasOwnProperty(getParam) &&
                (getParams[getParam as keyof typeof getParams] ||
                    typeof getParams[getParam as keyof typeof getParams] ==
                        'boolean')
            ) {
                let value = getParams[getParam as keyof typeof getParams];
                if (
                    Object.prototype.toString.call(value) === '[object Array]'
                ) {
                    for (var key in value as Array<string>) {
                        // testParam: ["1", "2", "3"] should be transformed into test=1&test=2&test=3
                        str.push(
                            encodeURIComponent(getParam) +
                                '=' +
                                encodeURIComponent(value[key])
                        );
                    }
                    continue;
                }
                str.push(
                    encodeURIComponent(getParam) +
                        '=' +
                        encodeURIComponent(value)
                );
            }
        return str.join('&');
    };

    getAppHeaders = (): AxiosHeaders => {
        return new AxiosHeaders()
            .set('INSTAPAID-BACK-OFFICE-APP-BUILD-NUMBER', '') //TODO: fill later
            .set('INSTAPAID-BACK-OFFICE', ''); //TODO: fill later
    };

    getAuthHeaders = async (companyId?: string): Promise<AxiosHeaders> => {
        const msalInstance = MSALHolder.msalInstance;
        const accounts = msalInstance?.getAllAccounts();
        const account = accounts?.[0];

        let request: PopupRequest | undefined = undefined;
        let msalHodler = await MSALHolder.getInstance();
        const loginType = getPersistedLoginType();
        if (loginType == 'B2C') {
            request = msalHodler.getLoginRequest();
        }
        if (loginType === 'AD') {
            request = msalHodler.getLoginRequestAD();
        }

        let token: string | undefined = '';

        try {
            const tokenResponse = await msalInstance?.acquireTokenSilent({
                ...request!,
                account: account!
            });
            token = tokenResponse?.accessToken;
        } catch (e) {
            console.error(e);
            logout();
        }

        let headers = this.getAppHeaders()
            .set('Authorization', `Bearer ${token}`)
            .set('X-Impersonated-UserId', getImpersonatedUserId())
            .set('companyId', companyId ?? getCompanyIdFromLocalStorage());

        return headers;
    };
}

export default BaseAPIServiceV2;
