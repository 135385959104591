import { useQuery } from '@tanstack/react-query';
import PaymentsAPIServiceV2 from './PaymentsServiceV2';

const paymentsService = PaymentsAPIServiceV2.getInstance();

export const PAYMENT_QUERY_KEYS: { [key: string]: string } = {
    PLANDAY_COMPANY_INFO: 'getPlandayCompanyInfo',
    TIME_REGISTRATION_PROVIDERS: 'getTimeRegistrationProviders',
    EMPLOYEE_TYPES: 'getEmployeeTypes',
    DEPARTMENTS: 'getDepartments',
    USER: 'getUser'
};

export const useTimeRegistrationProvidersQuery = (enabled: boolean) =>
    useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.TIME_REGISTRATION_PROVIDERS, enabled],
        queryFn: paymentsService.getTimeRegistrationProviders,
        enabled
    });

export const usePlandayCompanyInfoQuery = (enabled: boolean) =>
    useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.PLANDAY_COMPANY_INFO, enabled],
        queryFn: paymentsService.getPlandayCompanyInfo,
        enabled: enabled
    });

export const useEmployeeTypes = (enabled: boolean = true) =>
    useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.EMPLOYEE_TYPES, enabled],
        queryFn: paymentsService.getEmployeeTypes,
        enabled
    });

export const useEmployeeDepartments = (enabled: boolean = true) =>
    useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.DEPARTMENTS, enabled],
        queryFn: paymentsService.getEmployeeDepartments,
        enabled
    });

export const useGetUserQuery = ({
    userId,
    enabled
}: {
    userId?: string;
    enabled: boolean;
}) => {
    return useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.USER, userId],
        queryFn: () => paymentsService.getUser(userId!),
        enabled: !!userId && enabled
    });
};
