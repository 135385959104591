import { Box, Card, Grid, styled, Typography } from '@mui/material';
import {
    Bar,
    BarChart,
    Cell,
    DefaultTooltipContent,
    Pie,
    PieChart,
    Rectangle,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis
} from 'recharts';
import { useEffect, useState } from 'react';
import {
    SMMedium,
    TextLGSemiBold,
    TwoXRegular
} from '../../typography/Typography';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '../../utils/formatting';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { UseQueryResult } from '@tanstack/react-query';
import { EmployeeDetailResponse } from '../../services/ReportsInternalService';

const StyledCard = styled(Card)`
    padding: 24px 16px 24px 16px;
    gap: 8px;
    border-radius: 8px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px #1018280f;
    box-shadow: 0px 1px 3px 0px #1018281a;
`;

const NumberOfEmployee = styled(Box)`
    background-color: #1570ef;
    border-radius: 10px;
    height: 244px;
    color: white;
`;

const CountText = styled(Typography)`
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

const CountDescription = styled(Typography)`
    display: inline;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

const ChartContainer = styled(Box)`
    padding: 16px 0px 0px 0px;
    gap: 8px;
    border-radius: 4px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #eaecf0;
`;

const ChartHeader = styled(TextLGSemiBold)`
    padding: 0px 12px 12px 0px;
`;

const COLORS = ['#53B1FD', '#F97066'];

const COLORS2 = ['#EFF8FF', '#1570EF'];

const renderTitleWithSign = ({ x, y, width, value }: any) => {
    const sign = value > 0 ? '+' : '';
    const yOffset = value < 0 ? 20 : 0;

    let color = '#089855';
    if (value < 0) {
        color = '#F97066';
    }

    return (
        <>
            <text
                x={x + width / 2}
                y={y + yOffset}
                fill={color}
                textAnchor="middle"
                dy={-6}
                fontWeight="bold"
            >
                {value == 0 ? '' : sign + value}
            </text>
        </>
    );
};

const ColorFill = (props: any) => {
    const { dataKey } = props;

    let color = '#089855';
    if (dataKey == 'churnedCount') {
        color = '#F97066';
    }
    return (
        <Rectangle
            {...props}
            label={renderTitleWithSign}
            dataKey="uv"
            fill={color}
        />
    );
};

const ColorFill2 = (props: any) => {
    const { dataKey } = props;

    let color = '#089855';
    if (dataKey == 'churnedCount') {
        color = '#F97066';
    }
    return (
        <Rectangle
            {...props}
            label={renderTitleWithSign}
            dataKey="uv"
            fill={color}
        ></Rectangle>
    );
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            fontWeight={'bold'}
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

interface SicknessDashboardChartsProps {
    getEmployeeDetailsQuery: UseQueryResult<
        {
            data: EmployeeDetailResponse;
        },
        Error
    >;
}

const CustomToolTip = ({ ...props }: any) => {
    return <DefaultTooltipContent {...props} itemStyle={{ color: 'black' }} />;
};

export const SicknessDashboardCharts = ({
    getEmployeeDetailsQuery
}: SicknessDashboardChartsProps) => {
    const [employeeChurn, setEmployeeChurn] = useState<any[]>([]);
    const [employeeGenderRatio, setEmployeeGenderRatio] = useState<any[]>([]);
    const [employeeSicknessRatio, setEmployeeSicknessRatio] = useState<any[]>(
        []
    );

    const [employeeSickDaysByAge, setEmployeeSickDaysByAge] = useState<any[]>(
        []
    );

    useEffect(() => {
        if (getEmployeeDetailsQuery?.data?.data) {
            setEmployeeChurn(
                getEmployeeDetailsQuery?.data?.data?.employmentDates.map(
                    (el) => ({
                        name: el.date,
                        churnedCount: -1 * el.churnedCount,
                        hiredCount: el.hiredCount
                    })
                )
            );

            setEmployeeGenderRatio([
                {
                    value: getEmployeeDetailsQuery?.data?.data?.employeeGender
                        .malePercentage,
                    name: 'Male'
                },
                {
                    value: getEmployeeDetailsQuery?.data?.data?.employeeGender
                        .femalePercentage,
                    name: 'Female'
                }
            ]);

            setEmployeeSicknessRatio([
                {
                    value: getEmployeeDetailsQuery?.data?.data?.sickDays
                        ?.totalWorkedDays,
                    name: 'Worked Days'
                },
                {
                    value: getEmployeeDetailsQuery?.data?.data?.sickDays
                        ?.totalSickDays,
                    name: 'Sick Days'
                }
            ]);

            setEmployeeSickDaysByAge(
                getEmployeeDetailsQuery?.data?.data?.sickDaysByAge.map(
                    (el) => ({
                        description: el.description,
                        workedDays: el.totalWorkedDays,
                        sickDays: el.totalSickDays,
                        totalDays: el.totalEmployees
                    })
                )
            );
        }
    }, [getEmployeeDetailsQuery?.data?.data]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoader(getEmployeeDetailsQuery?.isPending));
    }, [getEmployeeDetailsQuery?.isPending]);
    const { t } = useTranslation();

    const { toDecimal } = useFormatting();

    const renderTitleForSickDaysByAge = ({
        x,
        y,
        width,
        value,
        offset,
        dataKey,
        index,
        height,
        ...props
    }: any) => {
        let color = '#1570EF';
        return (
            <>
                <>
                    <text
                        fontWeight={'bold'}
                        x={x + width / 2}
                        y={y + height / 2 + 10}
                        fill={color}
                        textAnchor="middle"
                        dy={-6}
                        z={100}
                    >
                        {getEmployeeDetailsQuery?.data?.data?.sickDaysByAge[
                            index
                        ].totalWorkedDays ?? 0}
                    </text>
                </>
            </>
        );
    };

    const renderTitleForSickDaysByAgeUpperText = ({
        x,
        y,
        width,
        value,
        offset,
        dataKey,
        index,
        height,
        ...props
    }: any) => {
        let color = '#F5FAFF';
        return (
            <>
                <text
                    fontWeight={'bold'}
                    x={x + width / 2}
                    y={y}
                    fill="#000"
                    textAnchor="middle"
                    dy={-6}
                    z={100}
                >
                    {getEmployeeDetailsQuery?.data?.data?.sickDaysByAge[index]
                        .totalEmployees ?? 0}
                </text>

                <text
                    fontWeight={'bold'}
                    x={x + width / 2}
                    y={y + height / 2 + 10}
                    fill={color}
                    textAnchor="middle"
                    dy={-6}
                    z={100}
                >
                    {getEmployeeDetailsQuery?.data?.data?.sickDaysByAge[index]
                        .totalSickDays ?? 0}
                </text>
            </>
        );
    };

    const renderTitle = ({
        x,
        y,
        width,
        height,
        value,
        offset,
        index
    }: any) => {
        return (
            <>
                <>
                    {
                        <text
                            fontWeight={'bold'}
                            x={x + width / 2}
                            y={y + height / 2 + 10}
                            fill="#fff"
                            textAnchor="middle"
                            dy={-6}
                            z={100}
                        >
                            {getEmployeeDetailsQuery?.data?.data
                                ?.employeeGenderByAge[index].maleCount ?? 0}
                        </text>
                    }
                </>
            </>
        );
    };

    const renderUpperTitle = ({ x, y, width, height, value, index }: any) => {
        return (
            <>
                <>
                    <text
                        fontWeight={'bold'}
                        x={x + width / 2}
                        y={y}
                        fill="#000"
                        textAnchor="middle"
                        dy={-6}
                        z={100}
                    >
                        {getEmployeeDetailsQuery?.data?.data
                            ?.employeeGenderByAge[index].totalEmployees ?? 0}
                    </text>

                    <text
                        fontWeight={'bold'}
                        x={x + width / 2}
                        y={y + height / 2 + 10}
                        fill="#fff"
                        textAnchor="middle"
                        dy={-6}
                        z={100}
                    >
                        {getEmployeeDetailsQuery?.data?.data
                            ?.employeeGenderByAge[index].femaleCount ?? 0}
                    </text>
                </>
            </>
        );
    };

    return (
        <Box>
            <Grid container>
                <Grid container gap={4}>
                    <Grid item md={4}>
                        <NumberOfEmployee>
                            <TextLGSemiBold
                                style={{
                                    padding: '24px 0 0 24px'
                                }}
                            >
                                {t('number_of_employees')}
                            </TextLGSemiBold>
                            <TwoXRegular px={3} py={1}>
                                {
                                    getEmployeeDetailsQuery?.data?.data
                                        .totalEmployees
                                }
                            </TwoXRegular>
                            <CountText px={3}>
                                {
                                    getEmployeeDetailsQuery?.data?.data
                                        .totalNewHires
                                }{' '}
                                <CountDescription>
                                    {t('new_employments')}
                                </CountDescription>
                            </CountText>
                            <CountText px={3}>
                                {
                                    getEmployeeDetailsQuery?.data?.data
                                        .totalChurned
                                }{' '}
                                <CountDescription>
                                    {t('resignations')}
                                </CountDescription>
                            </CountText>
                        </NumberOfEmployee>
                    </Grid>
                    <Grid item md={7.5}>
                        <StyledCard
                            style={{
                                height: '244px'
                            }}
                        >
                            <ChartHeader>
                                {t('employment_and_resignation')}
                            </ChartHeader>
                            <ChartContainer
                                style={{
                                    height: '160px'
                                }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        stackOffset="sign"
                                        data={employeeChurn}
                                    >
                                        <ReferenceLine y={0} stroke="#000" />
                                        <XAxis hide dataKey="name" />
                                        <Tooltip content={<CustomToolTip />} />
                                        <Bar
                                            label={renderTitleWithSign}
                                            dataKey="churnedCount"
                                            stackId={'a'}
                                            shape={<ColorFill />}
                                        />
                                        <Bar
                                            label={renderTitleWithSign}
                                            dataKey="hiredCount"
                                            stackId={'a'}
                                            shape={<ColorFill />}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </ChartContainer>
                        </StyledCard>
                    </Grid>
                    <Grid item md={4}>
                        <StyledCard
                            style={{
                                height: '336px'
                            }}
                        >
                            <ChartHeader>{t('employee_gender')}</ChartHeader>
                            <ChartContainer
                                style={{
                                    height: '252px'
                                }}
                            >
                                <ResponsiveContainer width="100%" height="85%">
                                    <PieChart width={200} height={200}>
                                        <Pie
                                            data={employeeGenderRatio}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {employeeGenderRatio.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            COLORS[
                                                                index %
                                                                    COLORS.length
                                                            ]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                                <SMMedium align="center">
                                    Theres{' '}
                                    <b>
                                        {
                                            getEmployeeDetailsQuery?.data?.data
                                                ?.employeeGender?.maleCount
                                        }
                                    </b>{' '}
                                    male and{' '}
                                    <b>
                                        {
                                            getEmployeeDetailsQuery?.data?.data
                                                ?.employeeGender?.femaleCount
                                        }
                                    </b>{' '}
                                    female employees
                                </SMMedium>
                            </ChartContainer>
                        </StyledCard>
                    </Grid>

                    <Grid item md={7.5}>
                        <StyledCard
                            style={{
                                height: '336px'
                            }}
                        >
                            <ChartHeader>
                                {t('employee_age_diversity')}
                            </ChartHeader>
                            <ChartContainer
                                style={{
                                    height: '252px'
                                }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={
                                            getEmployeeDetailsQuery?.data?.data
                                                ?.employeeGenderByAge ?? []
                                        }
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis
                                            fontWeight={'bold'}
                                            dataKey="description"
                                        />
                                        {/* <YAxis /> */}
                                        {/* <Tooltip /> */}
                                        {/* <Legend /> */}
                                        <Tooltip content={<CustomToolTip />} />
                                        <Bar
                                            label={renderTitle}
                                            dataKey="femaleCount"
                                            stackId="a"
                                            fill="#F97066"
                                        />
                                        <Bar
                                            label={renderUpperTitle}
                                            dataKey="maleCount"
                                            stackId="a"
                                            fill="#53B1FD"
                                            //shape={<ColorFill2 />}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </ChartContainer>
                        </StyledCard>
                    </Grid>
                    <Grid item md={4}>
                        <StyledCard
                            style={{
                                height: '433px'
                            }}
                        >
                            <ChartHeader>{t('sickdays')}</ChartHeader>
                            <ChartContainer
                                style={{
                                    height: '349px'
                                }}
                            >
                                <ResponsiveContainer width="100%" height="85%">
                                    <PieChart width={200} height={200}>
                                        <Pie
                                            data={employeeSicknessRatio}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {employeeSicknessRatio.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            COLORS2[
                                                                index %
                                                                    COLORS.length
                                                            ]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>

                                <SMMedium align="center">
                                    There been{' '}
                                    <b>
                                        {' '}
                                        {toDecimal(
                                            getEmployeeDetailsQuery?.data?.data
                                                ?.sickDays?.totalWorkedDays,
                                            2
                                        )}
                                    </b>{' '}
                                    days and{' '}
                                    <b>
                                        {toDecimal(
                                            getEmployeeDetailsQuery?.data?.data
                                                ?.sickDays?.totalSickDays,
                                            2
                                        )}
                                    </b>{' '}
                                    sickdays registered
                                </SMMedium>
                            </ChartContainer>
                        </StyledCard>
                    </Grid>
                    <Grid item md={7.5}>
                        <StyledCard
                            style={{
                                height: '433px'
                            }}
                        >
                            <ChartHeader>{t('sickdays_by_age')}</ChartHeader>
                            <ChartContainer
                                style={{
                                    height: '349px'
                                }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={employeeSickDaysByAge}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis
                                            fontWeight={'bold'}
                                            dataKey="description"
                                        />
                                        {/* <YAxis /> */}
                                        <Tooltip content={<CustomToolTip />} />
                                        {/* <Legend /> */}
                                        <Bar
                                            label={renderTitleForSickDaysByAge}
                                            dataKey="workedDays"
                                            fill="#D1E9FF"
                                            stackId={'a'}
                                        />
                                        <Bar
                                            label={
                                                renderTitleForSickDaysByAgeUpperText
                                            }
                                            dataKey="sickDays"
                                            fill="#1570EF"
                                            stackId={'a'}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </ChartContainer>
                        </StyledCard>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
