import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextFieldProps,
    FormControl,
    InputLabel,
    FormControlProps,
    Select,
    MenuItem,
    FormHelperText,
    Grid,
    SelectChangeEvent
} from '@mui/material';
import { useState } from 'react';
import { CreateCompanyAccountTransactionRequest } from '../../models/payment';
import { Validator } from '../../utils/errorHandling';
import { convertStringInputToNumber } from '../../utils/formatting';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { CompanyAccount, CompanyAccountsResponse } from '../../models/company';
import { AccountUpdateResult } from './AccountUpdateResult';

export type AddCompanyAccountTransactionDialogMode = 'Deposit' | 'Withdraw';

type AddCompanyAccountTransactionDialogProps = {
    open: boolean;
    mode: AddCompanyAccountTransactionDialogMode;
    companyAccounts: CompanyAccountsResponse;
    onClose: () => void;
    onSubmit: (
        request: CreateCompanyAccountTransactionRequest,
        onSuccess: () => void
    ) => Promise<void>;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
};

export const AddCompanyAccountTransactionDialog = (
    props: AddCompanyAccountTransactionDialogProps
) => {
    const sortedCompanyAccounts = props.companyAccounts.companyAccounts.sort(
        (a, b) => a.name?.localeCompare(b.name)
    );

    const [companyId, setCompanyId] = useState<string>(
        sortedCompanyAccounts.length > 0
            ? sortedCompanyAccounts[0].companyId
            : ''
    );
    const [companyAccount, setCompanyAccount] = useState<CompanyAccount | null>(
        sortedCompanyAccounts.length > 0 ? sortedCompanyAccounts[0] : null
    );
    const [amount, setAmount] = useState<string>('');
    const { setAlert } = useNotificationsSnackbar();

    const onSubmit = () => {
        const amountDecimal = convertStringInputToNumber(amount);

        if (amountDecimal === undefined || amountDecimal === null) {
            setAlert('Amount must be a number', 'error');
            return;
        }

        if (!companyAccount) {
            setAlert('Please select a company', 'error');
            return;
        }
        const amountSigned =
            props.mode === 'Deposit' ? amountDecimal : amountDecimal * -1;

        if (
            props.mode === 'Withdraw' &&
            companyAccount.balance + amountSigned < 0
        ) {
            setAlert(
                `You cannot withdraw more than ${companyAccount.balance} ${companyAccount.currency} - the new balance has to be >= zero.`,
                'error'
            );
            return;
        }

        props.onSubmit(
            {
                companyId: companyAccount?.companyId,
                amount: amountSigned
            },
            clearState
        );
    };

    const clearState = () => {
        setCompanyAccount(null);
        setCompanyId('');
        setAmount('');
    };

    const getAmountNumber = () => {
        const amountNumber = convertStringInputToNumber(amount);
        if (amountNumber === null || amountNumber === undefined) {
            return 0;
        }
        return amountNumber;
    };

    const onCompanyAccountChange = (e: SelectChangeEvent<string>) => {
        setCompanyId(e.target.value);
        const companyAccount = sortedCompanyAccounts.find(
            (_) => _.companyId === e.target.value
        );
        setCompanyAccount(companyAccount ?? null);
        props.setValidator(props.validator.clearError('companyId'));
    };

    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    return (
        <>
            {sortedCompanyAccounts.length > 0 && (
                <Dialog open={props.open} onClose={props.onClose}>
                    <DialogTitle>{props.mode}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl
                                    {...(commonProps as FormControlProps)}
                                >
                                    <InputLabel>Company</InputLabel>
                                    <Select
                                        variant="standard"
                                        value={companyId}
                                        label="Company"
                                        error={props.validator.hasError(
                                            'companyId'
                                        )}
                                        onChange={onCompanyAccountChange}
                                    >
                                        {sortedCompanyAccounts.map(
                                            (companyAccount) => (
                                                <MenuItem
                                                    key={
                                                        companyAccount.companyId
                                                    }
                                                    value={
                                                        companyAccount.companyId
                                                    }
                                                >
                                                    {companyAccount.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        {props.validator.getError('loginType')}
                                    </FormHelperText>
                                </FormControl>

                                <TextField
                                    {...commonProps}
                                    error={props.validator.hasError('amount')}
                                    helperText={props.validator.getError(
                                        'amount'
                                    )}
                                    name="amount"
                                    type="number"
                                    label="Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.currentTarget.value);
                                        props.setValidator(
                                            props.validator.clearError('amount')
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                {companyAccount && (
                                    <AccountUpdateResult
                                        currency={companyAccount.currency}
                                        balance={companyAccount.balance}
                                        amount={getAmountNumber()}
                                        mode={props.mode}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Cancel</Button>
                        <Button variant="contained" onClick={onSubmit}>
                            {props.mode}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
