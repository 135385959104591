import { useDispatch, useSelector, useStore } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch, AppStore } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;

export const usePayments = () => useAppSelector((state) => state.payments);

export const useUsers = () => useAppSelector((state) => state.users);
export const useUpcomingShifts = () =>
    useAppSelector((state) => state.payments.upcomingShifts);
export const useLoader = () => useAppSelector((state) => state.loader);

export const useCompany = () =>
    useAppSelector((state) => state.payments.currentCompany);

export const useAdminCompanies = () =>
    useAppSelector((state) => state.payments.adminCompanies);
