import dayjs from "dayjs";
import {
  BalanceDetails,
  BankDetailsResponse,
  CompanyResponse,
  PendingTransactionsResponse,
} from "./types";

export const HourlyEmployee = "Hourly";

export const useWithdrawalValidation = (
  company?: CompanyResponse | null,
  balanceDetails?: BalanceDetails,
  bankDetails?: BankDetailsResponse | null,
  pendingTransactions?: number
) => {
  const isFullTimeEmployee = balanceDetails?.employmentType != HourlyEmployee;

  const dayExceededLastWithdrawal = company?.latestWithdrawalDay
    ? isFullTimeEmployee && company?.latestWithdrawalDay < dayjs().date()
    : false;

  const currentMonthPayrollExists =
    balanceDetails?.cannotWithdrawAfterPayRollProcessed ||
    dayExceededLastWithdrawal;

  const bankDetailsEmpty =
    !(bankDetails?.bankCode || bankDetails?.accountNumber) &&
    !bankDetails?.isNemkonto;

  const alreadyProcessingTransactions = pendingTransactions! > 0 || false;
  const isNemkonto = bankDetails?.isNemkonto;

  return {
    isFullTimeEmployee,
    dayExceededLastWithdrawal,
    bankDetailsEmpty,
    currentMonthPayrollExists,
    alreadyProcessingTransactions,
    isNemkonto,
  } as const;
};
