import { MSALHolder } from '../lib/msal';

export const logout = () => {
    const msalInstance = MSALHolder.msalInstance;
    window.sessionStorage.clear();
    window.localStorage.clear();
    msalInstance?.logoutRedirect({
        postLogoutRedirectUri: '/'
    });
};
